<template>
  <div class="404">
    404
  </div>
</template>

<script>
export default {
  name: "homeIndex",
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.homeIndex {
  font-size: 0.16rem;
  width: 100%;
}
</style>
